import { css } from "../../../styled-system/css";
import Select from "../../baseComps/Select.tsx";
import { formLabelTextStyle } from "./styles.ts";
import { formaters } from "vincent-utils";
import { Collections, HoraireInDB } from "cadendar-shared";
import useAptTimeCaracs from "./hooks/useAptTimeCaracs.ts";
import { Button2 } from "../../baseComps/Button.tsx";

interface SpecialAptCaracsProps {
  heure: number;
  minute: number;
  date: Date;
  duree: number;
  horaire: HoraireInDB;
  dayRdvs: Collections.Rdv[];
  prefs: { duree_min_rdv: number };
  onCommit: ({
    heure,
    minute,
    duree,
  }: {
    heure: number;
    minute: number;
    duree: number;
  }) => void;
}

const SpecialAptCaracs = (props: SpecialAptCaracsProps) => {
  const { timedApt, onSetDuree, onSetHM, hmPossibles, dureesPossibles } =
    useAptTimeCaracs(
      {
        heure: props.heure,
        minute: props.minute,
        date: props.date,
        duree: props.duree,
      },
      props.horaire,
      props.dayRdvs,
      props.prefs
    );
  const handleCommit = () => {
    props.onCommit({
      heure: timedApt.heure,
      minute: timedApt.minute,
      duree: timedApt.duree,
    });
  };
  return (
    <div>
      <div className={css({ display: "flex", marginY: 5 })}>
        <div className={css({ marginX: 10 })}>
          <div className={formLabelTextStyle()}>Heure</div>
          <Select
            value={
              (timedApt &&
                formaters.formatHMObjectForDisplay({
                  heure: timedApt.heure,
                  minute: timedApt.minute,
                })) ||
              "0:00"
            }
            onChange={(event) => {
              const splitted = event.target.value.split(":");
              const heure = parseInt(splitted[0]);
              const minute = parseInt(splitted[1]);
              onSetHM({ heure, minute });
            }}
          >
            {hmPossibles &&
              hmPossibles.map((hm) => (
                <option
                  value={formaters.formatHMObjectForDisplay(hm)}
                  key={JSON.stringify(hm)}
                >
                  {formaters.formatHMObjectForDisplay(hm)}
                </option>
              ))}
          </Select>
        </div>
        <div>
          <div className={css({})}>
            <div className={formLabelTextStyle()}>Durée</div>
            <Select
              value={(timedApt && timedApt.duree) || 0}
              onChange={(event) => {
                onSetDuree(parseInt(event.target.value));
              }}
              className={css({
                backgroundColor: "white",
              })}
            >
              {dureesPossibles &&
                dureesPossibles.map((duree) => (
                  <option
                    value={duree}
                    key={duree}
                    className={css({
                      backgroundColor: "white",
                    })}
                  >
                    {duree}
                  </option>
                ))}
            </Select>
          </div>
        </div>
      </div>
      <div className={css({ display: "flex", justifyContent: "center" })}>
        <Button2 color="proceed" onClick={handleCommit}>
          Ok
        </Button2>
      </div>
    </div>
  );
};

export default SpecialAptCaracs;
