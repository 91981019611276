import { Collections, Transforms } from "cadendar-shared";
import { useEffect, useState } from "react";
import { useHoraires } from "../../horaires/hooks/useHoraires.ts";
import { usePreferences } from "../../preferences/hooks/usePreferences.ts";
import useApptsByDateWindow from "./useApptsByDateWindow.ts";

export type EditRdvState = Collections.Rdv & {
  reminderChecked: boolean;
  confirmChecked: boolean;
  nom: string;
  prenom: string;
  objet: string;
};

const getInitialState = (rdv: Collections.Rdv): EditRdvState => {
  return {
    ...rdv,
    reminderChecked: !!rdv.reminders && rdv.reminders.length > 0,
    confirmChecked: !!rdv.confirmationSms && rdv.confirmationSms.length > 0,
    nom: rdv.nom || "",
    prenom: rdv.prenom || "",
    objet: rdv.objet || "",
  };
};

const useEditRdv = (rdvState: Collections.Rdv | null, date: Date) => {
  const [tempRdv, setTempRdv] = useState(
    (rdvState && getInitialState(rdvState)) || null
  );

  useEffect(() => {
    if (!rdvState) return;
    setTempRdv(getInitialState(rdvState));
  }, [JSON.stringify(rdvState)]);
  const horaire = useHoraires(tempRdv && tempRdv.date);
  const allRdvs = useApptsByDateWindow(date, date);
  const prefs = usePreferences();
  const onSetHM = ({ heure, minute }: { heure: number; minute: number }) => {
    setTempRdv((tempRdv) => {
      if (!tempRdv) return null;
      const dureesPossibles =
        horaire &&
        prefs &&
        tempRdv &&
        Transforms.rdvs.getDurees2(
          horaire,
          allRdvs,
          tempRdv.date,
          { heure, minute },
          prefs.duree_min_rdv,
          tempRdv
        );
      const choosenDuree =
        (dureesPossibles &&
          (dureesPossibles.includes(tempRdv.duree)
            ? tempRdv.duree
            : dureesPossibles[0])) ||
        0;
      return { ...tempRdv, heure, minute, duree: choosenDuree };
    });
  };

  const onSetDuree = (duree: number) => {
    setTempRdv((tempRdv) => (tempRdv ? { ...tempRdv, duree } : null));
  };
  const onSetType = (type: number) => {
    setTempRdv((tempRdv) => (tempRdv ? { ...tempRdv, type } : null));
  };
  const onSetObjet = (objet: string) => {
    setTempRdv((tempRdv) => (tempRdv ? { ...tempRdv, objet } : null));
  };
  const onToggleReminder = () => {
    setTempRdv((tempRdv) =>
      tempRdv
        ? {
            ...tempRdv,
            reminderChecked: !tempRdv.reminderChecked,
          }
        : null
    );
  };
  const onToggleConfirm = () => {
    setTempRdv((tempRdv) =>
      tempRdv
        ? {
            ...tempRdv,
            confirmChecked: !tempRdv.confirmChecked,
          }
        : null
    );
  };
  const hmPossibles =
    horaire &&
    prefs &&
    rdvState &&
    Transforms.rdvs.hmAvailableWithDuration(
      horaire,
      allRdvs,
      rdvState.date,
      prefs.duree_min_rdv,
      (tempRdv && tempRdv.duree) || rdvState.duree,
      tempRdv || rdvState
    );
  const dureesPossibles =
    (tempRdv &&
      prefs &&
      horaire &&
      Transforms.rdvs.getDurees2(
        horaire,
        allRdvs,
        date,
        { heure: tempRdv.heure, minute: tempRdv.minute },
        prefs.duree_min_rdv,
        tempRdv
      )) ||
    [];
  return {
    tempRdv,
    hmPossibles,
    dureesPossibles,
    onSetHM,
    onSetDuree,
    onSetType,
    onSetObjet,
    onToggleReminder,
    onToggleConfirm,
  };
};

export { useEditRdv };
