import { useReducer, useState } from "react";
import { trpc } from "../../main/trpc.ts";
import ChoosePatientDialog from "./ChoosePatientDialog.tsx";
import { css } from "../../../styled-system/css";
import { formaters } from "vincent-utils";
import { circle } from "../../../styled-system/patterns";
import { DateTime } from "luxon";
import XMark from "../../assets/xmark-svgrepo-com.svg?react";
import CheckBox from "../../baseComps/CheckBox.tsx";
import { Button2 } from "../../baseComps/Button.tsx";
import SpecialAptCaracs from "./SpecialAptCaracs.tsx";
import { Collections } from "cadendar-shared";
import styles from "./NewAptFirstScreen.module.css";
import NewAptSecondScreen from "./NewAptSecondScreen.tsx";

interface NewAptScreenHeaderProps {
  onCancel: () => void;
  date: Date;
}
const NewAptScreenHeader = (props: NewAptScreenHeaderProps) => {
  return (
    <div className={styles.topDiv}>
      <div>
        <div className={styles.spacer}>
          <div className={styles.topMessage}>Nouveau RDV</div>
          <span
            className={circle({
              size: "2em",
              backgroundColor: "white",
              cursor: "pointer",
            })}
            onClick={props.onCancel}
          >
            <XMark
              className={css({
                width: "1em",
                height: "1em",
                color: "black",
              })}
            />
          </span>
        </div>
      </div>
      <div>
        <div>
          <div className={styles.date}>
            {formaters.capitaliseEveryFirstLetter(
              DateTime.fromJSDate(props.date)
                .setLocale("fr")
                .toFormat("cccc dd LLL y")
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

interface BottomWindowHeaderProps {
  title: string;
  onCancelEdit: () => void;
  date: Date;
}

const BottomWindowHeader = (props: BottomWindowHeaderProps) => {
  return (
    <div className={styles.topDiv}>
      <div>
        <div className={styles.spacer}>
          <div className={styles.topMessage}>{props.title}</div>
          <span className={styles.circle} onClick={props.onCancelEdit}>
            <svg
              width="800px"
              height="800px"
              viewBox="0 0 19 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={styles.icon}
            >
              <path
                className={styles.icon}
                d="M6 6L18 18M18 6L6 18"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </div>
      </div>
      <div>
        <div>
          <div className={styles.date}>
            {formaters.capitaliseEveryFirstLetter(
              DateTime.fromJSDate(props.date)
                .setLocale("fr")
                .toFormat("cccc dd LLL y")
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

interface OtherOptionsDialogProps {
  optionChoosen: OtherOptionsKeys | null;
  onToggleOption: (option: OtherOptionsKeys) => void;
}

const OtherOptionsDialog = (props: OtherOptionsDialogProps) => {
  return (
    <div
      className={css({
        borderStyle: "none",
        borderWidth: 2,
        borderColor: "gray.200",
        borderRadius: 4,
        backgroundColor: "gray.100",
        marginX: 2,
        marginY: 2,
      })}
    >
      <div>
        <CheckBox
          label="Fermer la plage"
          checked={props.optionChoosen === "closePlage"}
          onChange={() => props.onToggleOption("closePlage")}
          marginLeft={2}
        />
      </div>
      <div>
        <CheckBox
          label="Fermer la journée"
          checked={props.optionChoosen === "closeWholeDay"}
          onChange={() => props.onToggleOption("closeWholeDay")}
          marginLeft={2}
        />
      </div>
      <div>
        <CheckBox
          label="Réserver la plage pour Doctolib"
          checked={props.optionChoosen === "reserveForDoctolib"}
          onChange={() => props.onToggleOption("reserveForDoctolib")}
          marginLeft={2}
        />
      </div>
    </div>
  );
};

interface NewAptScreenProps {
  dateSelected: Date;
  heure: number;
  minute: number;
  dayRdvs: Collections.Rdv[];
  onSuccess: () => void;
  onError: () => void;
  onClose: () => void;
  onPatientEditClick: (_id: string) => void;
}

type OtherOptionsKeys = "closePlage" | "closeWholeDay" | "reserveForDoctolib";

interface State {
  search: string;
  patientSelectedId: string | null;
  otherOptionsChoosen: OtherOptionsKeys | null;
}

type Action =
  | {
      type: "searchPatient";
      payload: string;
    }
  | {
      type: "choosePatient";
      payload: string | null;
    }
  | {
      type: "toggleOption";
      payload: OtherOptionsKeys;
    }
  | {
      type: "reset";
    };

function reducer(state: State, action: Action) {
  switch (action.type) {
    case "searchPatient":
      return { ...state, search: action.payload, otherOptionsChoosen: null };
    case "choosePatient":
      return {
        ...state,
        patientSelectedId: action.payload,
        otherOptionsChoosen: null,
      };
    case "toggleOption":
      return {
        ...state,
        otherOptionsChoosen:
          state.otherOptionsChoosen === action.payload ? null : action.payload,
        patientSelectedId: null,
        search: "",
      };
    case "reset":
      return { search: "", patientSelectedId: null, otherOptionsChoosen: null };
    default:
      return state;
  }
}

const NewAptFirstScreen = (props: NewAptScreenProps) => {
  const [state, dispatch] = useReducer(reducer, {
    search: "",
    patientSelectedId: null,
    otherOptionsChoosen: null,
  });

  console.log("state", state);
  // const [otherOptionsChoosen, setOtherOptionsChoosen] =
  //   useState<OtherOptionsKeys | null>(null);
  // const [patientSelectedId, setPatientSelectedId] = useState<string | null>(
  //   null
  // );
  const [step, setStep] = useState<"firstStep" | "secondStep">("firstStep");

  const trpcutils = trpc.useContext();

  const { data: patients } = trpc.patient.findByName.useQuery(
    { nameLookup: state.search },
    { enabled: !!state.search && state.search.length > 1 }
  );

  if (patients && patients.length > 1 && !state.patientSelectedId) {
    dispatch({ type: "choosePatient", payload: patients[0]._id });
  }

  if (
    (!patients || (patients && patients.length === 0)) &&
    state.patientSelectedId
  ) {
    dispatch({ type: "choosePatient", payload: null });
  }

  const { data: patient } = trpc.patient.getById.useQuery(
    state.patientSelectedId as string,
    { enabled: !!state.patientSelectedId }
  );
  console.log("patientSelectedId", state.patientSelectedId);
  console.log("patient", patient);

  const closeDayMutation = trpc.rdv.closeWholeDay.useMutation({
    onSuccess: () => {
      props.onSuccess();

      trpcutils.rdv.invalidate();
      dispatch({ type: "reset" });
    },
  });
  const handleCloseDay = () => {
    closeDayMutation.mutate({ date: props.dateSelected });
  };

  const closePlageMutation = trpc.rdv.closePlage.useMutation({
    onSuccess: () => {
      props.onSuccess();
      trpcutils.rdv.invalidate();
      dispatch({ type: "reset" });
      setStep("firstStep");
    },
  });
  const handleClosePlage = ({
    heure,
    minute,
    duree,
  }: {
    heure: number;
    minute: number;
    duree: number;
  }) => {
    console.log("closing plage", props.dateSelected);
    closePlageMutation.mutate({
      date: props.dateSelected,
      heure,
      minute,
      duree,
    });
  };

  const reserveForDoctolibMutation = trpc.rdv.reserveForDocto.useMutation({
    onSuccess: () => {
      props.onSuccess();
      trpcutils.rdv.invalidate();
      dispatch({ type: "reset" });
      setStep("firstStep");
    },
  });

  const handleReserveForDoctolib = ({
    heure,
    minute,
    duree,
  }: {
    heure: number;
    minute: number;
    duree: number;
  }) => {
    console.log("reserve for doctolib", props.dateSelected);
    reserveForDoctolibMutation.mutate({
      date: props.dateSelected,
      heure,
      minute,
      duree,
    });
  };
  const firstStepAnswered =
    !!state.patientSelectedId || !!state.otherOptionsChoosen;

  console.log("firstStepAnswered", firstStepAnswered);

  const handleSearchPatient = (str: string) => {
    dispatch({ type: "searchPatient", payload: str });
  };

  const handleChoosePatient = (id: string | null) => {
    console.log("handleChoosePatient", id);
    dispatch({ type: "choosePatient", payload: id });
  };

  const handleNextStep = () => {
    if (state.patientSelectedId) {
      setStep("secondStep");
      console.log("patientSelectedId", state.patientSelectedId);
    }
    if (state.otherOptionsChoosen === "closeWholeDay") {
      handleCloseDay();
      return;
    }
    if (state.otherOptionsChoosen === "closePlage") {
      setStep("secondStep");
      console.log("closePlage");
      return;
    }
    if (state.otherOptionsChoosen === "reserveForDoctolib") {
      setStep("secondStep");
      console.log("reserveForDoctolib");
      return;
    }
  };

  const handleToggleOption = (option: OtherOptionsKeys) => {
    dispatch({ type: "toggleOption", payload: option });
  };

  const { data: prefs } = trpc.preference.get.useQuery();
  const { data: horaires } = trpc.horaire.byDate.useQuery({
    date: props.dateSelected,
  });

  if (step === "firstStep") {
    return (
      <div>
        <NewAptScreenHeader
          onCancel={props.onClose}
          date={props.dateSelected}
        />
        <div className={styles.buttonSpacer}>
          <button className={styles.button}>Créer un nouveau patient</button>
        </div>
        <div className={styles.ou}>Ou</div>
        <ChoosePatientDialog
          onChoosePatient={handleChoosePatient}
          onCancel={() => {}}
          patientSelectedId={state.patientSelectedId}
          search={state.search}
          onSetSearch={handleSearchPatient}
          candidates={patients || []}
        />
        {!state.patientSelectedId ? (
          <>
            <div className={styles.ou}>Ou</div>
            <OtherOptionsDialog
              onToggleOption={handleToggleOption}
              optionChoosen={state.otherOptionsChoosen}
            />
          </>
        ) : null}
        <div
          className={css({
            display: "flex",
            justifyContent: "center",
            marginY: 4,
          })}
        >
          <Button2
            onClick={handleNextStep}
            disabled={!firstStepAnswered}
            color="proceed"
          >
            Suivant
          </Button2>
        </div>
      </div>
    );
  }
  if (step === "secondStep") {
    if (
      state.otherOptionsChoosen === "closePlage" &&
      prefs?.duree_min_rdv &&
      horaires
    ) {
      return (
        <div>
          <BottomWindowHeader
            title={"Fermeture de la plage"}
            onCancelEdit={() => {
              dispatch({ type: "reset" });
              setStep("firstStep");
            }}
            date={props.dateSelected}
          />
          <SpecialAptCaracs
            heure={props.heure}
            minute={props.minute}
            date={props.dateSelected}
            duree={prefs.duree_min_rdv}
            horaire={horaires}
            dayRdvs={props.dayRdvs}
            prefs={prefs}
            onCommit={handleClosePlage}
          />
        </div>
      );
    }
    if (
      state.otherOptionsChoosen === "reserveForDoctolib" &&
      horaires &&
      prefs
    ) {
      return (
        <div>
          <BottomWindowHeader
            title={"Réserver pour Doctolib"}
            onCancelEdit={() => {
              dispatch({ type: "reset" });
              setStep("firstStep");
            }}
            date={props.dateSelected}
          />
          <SpecialAptCaracs
            heure={props.heure}
            minute={props.minute}
            date={props.dateSelected}
            duree={prefs.duree_min_rdv}
            horaire={horaires}
            dayRdvs={props.dayRdvs}
            prefs={prefs}
            onCommit={handleReserveForDoctolib}
          />
        </div>
      );
    }
    if (state.otherOptionsChoosen === null && patient) {
      return (
        <NewAptSecondScreen
          hour={props.heure}
          minute={props.minute}
          date={props.dateSelected}
          patient={patient}
          onCancel={props.onClose}
          onSuccessfulSave={props.onSuccess}
          onSaveError={props.onError}
          onPatientEditClick={props.onPatientEditClick}
        />
      );
    }
  }
};

export default NewAptFirstScreen;
